<template>
    <r-e-dialog title="预览" show-footer :visible.sync="dialogVisible" width="600px" :footerBtnName="['取消', '打印']"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <div class="flex justify-center">
            <el-image :src="url" style="border: 1px solid #EEEEEE">
                <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                </div>
            </el-image>
        </div>
    </r-e-dialog>
</template>

<script>
import jcPrinterSdk from '@/assets/js/jcPrinterSdk_api_third';
import {combinationPrintData, fangjianming} from '../js/label-template';

export default {
    name: "layer-label-preview",
    data() {
        return {
            dialogVisible: false,
            loading: null,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            url: null,
            combinationPrint: {}
        }
    },
    components: {},
    methods: {
        async openDialog(data) {
            // console.log(JSON.stringify(data));
            const {uuid, assetType, name, asstesName} = data;
            const {InitDrawingBoardParam, elements} = JSON.parse(JSON.stringify(combinationPrintData));
            elements[0].json.value = `https://rental.neoyon.com/h5/assets/index.html?uuid=${uuid}_;`;
            // elements[1].json.value = "南宁富航公司\n" + assetType;
            elements[1].json.value = "南宁富航公司\n" + asstesName;
            if (name) {
                const fangjianming1 =  JSON.parse(JSON.stringify(fangjianming));
                elements.push(fangjianming1);
                elements[2].json.value = name;
            }
            this.combinationPrint = {InitDrawingBoardParam, elements}
            console.log(this.combinationPrint);
            this.startPrintJobTest(true);
            this.dialogVisible = true;
        },

        clickSubmit() {
            const that = this;
            const loadingOptions = that.loadingOptions;
            that.loading = that.$loading({...loadingOptions});
            this.startPrintJobTest(false);
        },

        clickCancel() {
            this.dialogVisible = false;
            this.url = null;
        },

        startPrintJobTest(isPreview) {
            let contentArr = [];
            const that = this;
            const {combinationPrint} = this;
            contentArr.push(combinationPrint);
            jcPrinterSdk.batchPrintJob(that, contentArr, isPreview);
        },
    },
}
</script>

<style lang="scss" scoped>
</style>